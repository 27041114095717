import React from 'react';

const LogoWhatsapp: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_47_1040)">
        <path d="M0.0536804 24L1.74139 17.8373C0.70037 16.0338 0.15258 13.9878 0.15343 11.8918C0.15606 5.33464 5.49276 0 12.0499 0C15.2322 0.00127 18.2192 1.23986 20.4652 3.48773C22.7113 5.73561 23.9475 8.7236 23.9463 11.9013C23.9436 18.4584 18.6065 23.7938 12.05 23.7938H12.05H12.0451C10.0541 23.793 8.09773 23.2937 6.36015 22.3463L0.0536804 24ZM6.65232 20.1932L7.01339 20.4074C8.53149 21.3081 10.2717 21.7845 12.0459 21.7852H12.05C17.4999 21.7852 21.9355 17.351 21.9377 11.9005C21.9387 9.25935 20.9112 6.77584 19.0444 4.90748C17.1775 3.03912 14.6949 2.00959 12.0539 2.00858C6.59986 2.00858 2.16418 6.44251 2.162 11.8926C2.16124 13.7602 2.68401 15.5792 3.67378 17.153L3.90891 17.527L2.91 21.1746L6.65232 20.1932Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.07673 6.92047C8.83547 6.34102 8.5904 6.41942 8.40794 6.41034C8.23476 6.40172 8.0364 6.3999 7.83824 6.3999C7.64008 6.3999 7.31806 6.47426 7.04558 6.77173C6.77312 7.06922 6.00525 7.78814 6.00525 9.25073C6.00525 10.7133 7.07036 12.1263 7.21899 12.3247C7.3676 12.523 9.31501 15.5245 12.2968 16.8116C13.006 17.1178 13.5597 17.3006 13.9914 17.4376C14.7035 17.6637 15.3515 17.6318 15.8637 17.5553C16.4347 17.47 17.6223 16.8364 17.87 16.1423C18.1177 15.4482 18.1177 14.8532 18.0434 14.7293C17.9691 14.6053 17.7709 14.531 17.4737 14.3822C17.1764 14.2335 15.715 13.5146 15.4425 13.4154C15.1701 13.3163 14.9719 13.2667 14.7738 13.5642C14.5756 13.8616 14.006 14.531 13.8326 14.7293C13.6592 14.9276 13.4858 14.9524 13.1886 14.8036C12.8913 14.6549 11.9335 14.3411 10.7982 13.3288C9.91455 12.5409 9.31794 11.5677 9.14455 11.2702C8.97116 10.9727 9.12611 10.8119 9.27491 10.6637C9.40861 10.5306 9.57214 10.3167 9.72077 10.1432C9.86938 9.96962 9.91893 9.84567 10.018 9.64737C10.1171 9.44904 10.0675 9.27551 9.99323 9.12677C9.91891 8.97804 9.32445 7.51542 9.07673 6.92047Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_1040">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoWhatsapp;
